
  import { Vue, Component } from "vue-property-decorator";
  import {
    dispatchGetUpcomingEvents,
    dispatchCheckApiError,
  } from "@/store/main/actions";
  import { readUpcomingEvents, readIsLoggedIn, readToken } from "@/store/main/getters";
  import { formatDate, formatTimeRange } from "@/date-utils";
  import { IEvent } from "@/interfaces";
  import { api } from "@/api";
  import axios from "axios";

  @Component
  export default class Events extends Vue {
    dialog = false;
    loading = false;

    get events() {
      return readUpcomingEvents(this.$store);
    }

    get required() {
      return this.$route.query.required === "true";
    }

    isVirtual(event: IEvent) {
      return event && event.venue === "virtual event";
    }

    formatDate(date: string, utc_offset: string) {
      if (date && utc_offset) {
        return formatDate(date, utc_offset);
      }
      return "";
    }

    formatTime(start: string, end: string, utc_offset: string, time_zone: string) {
      if (start && end && utc_offset && time_zone) {
        return formatTimeRange(start, end, utc_offset, time_zone);
      }
      return "";
    }

    formatTitle(title: string) {
      return title.startsWith("MDTP Certification") ? "MDTP Certification" : title;
    }

    async goToThinkific() {
      if (readIsLoggedIn(this.$store)) {
        const token = readToken(this.$store);
        try {
          this.loading = true;
          const resp = await api.getThinkificSSOUrl(
            token,
            false,
            false,
            "https://degluted.fdi2.com",
          );
          window.location.href = resp.data.url;
          this.loading = false;
        } catch (error) {
          this.loading = false;
          dispatchCheckApiError(this.$store, error);
          // Redirect if a response actually came back, if a network error
          // or something else went wrong just let them try again
          if (axios.isAxiosError(error)) {
            if (error.response?.status) {
              window.location.href = "https://degluted.fdi2.com";
            }
          }
        }
      } else {
        window.location.href = "https://degluted.fdi2.com";
      }
    }

    public async mounted() {
      if (this.required) {
        this.dialog = true;
      }
      await dispatchGetUpcomingEvents(this.$store);
    }
  }
